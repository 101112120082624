<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-row>
      <v-col cols="10">
        <h4>Select Category</h4>
        <v-radio-group
          class="radio-group py-0 ma-0 px-1"
          small
          v-model="category2"
          row
        >
          <v-radio
            :disabled="formLoading"
            color="#2C1963"
            label="Wizfit"
            value="wizfit"
          ></v-radio>
          <v-radio
            :disabled="formLoading"
            color="#2C1963"
            label="Game"
            value="game"
            @change="routeGame()"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="2">
        <v-btn
          color="#38227A"
          dark
          class="px-11 text-capitalize ml-3"
          height="45px"
          style="border-radius: 10px; margin-top: 35px"
          @click="route()"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <span>Upload</span>
        </v-btn>
        <v-menu offset-y v-if="$vuetify.breakpoint.xsOnly">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mobile-menu"
              >mdi-menu</v-icon
            >
          </template>
          <v-list>
            <v-list-item-group active-class="active-class">
              <v-list-item @click="route()">
                <v-list-item-title>Upload</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="3" class="pl-3">
        <v-autocomplete
          label="Category"
          outlined
          dense
          v-model="category"
          :items="categoryList"
          item-value="id"
          item-text="category_name"
          class="formFields"
          color="#7253CF"
          @change="getCampaignManagementData()"
        >
        </v-autocomplete>
      </v-col>
    </v-row>
    <div class="table-div" style="margin-top: 25px">
      <v-data-table
        hide-default-footer
        fixed-header
        :headers="campaignDataTable.headers"
        :items="campaignDataTable.items"
        :loading="campaignDataTable.loading"
        :items-per-page="
          campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint
      >
        <template v-slot:item="row">
          <tr>
            <td class="text-center row-item">
              <div>
                {{ row.item.title }}
              </div>
            </td>
            <td class="text-center row-item">{{ row.item.notes }}</td>
        <!--    <td class="text-center row-item" v-if="row.item.video_url">
              <a :href="row.item.video_url" target="_blank"> URL</a>
            </td>
            <td class="text-center row-item" v-else></td>-->
            <td class="text-center row-item">
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="editResource(row.item)"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-chip>
              <v-chip
                @click="getReport(row.item.id)"
                class="actionColumn-btn"
                color="#F2F2F2"
                :loading="btnLoading && indexClicked === row.item.id"
                v-if="row.item.document"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>Download </span>
                </v-tooltip>
              </v-chip>
               <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
               v-else
              >
               <a :href="row.item.video_url" target=_blank > URL</a>
               </v-chip>
              <v-chip
                class="actionColumn-btn"
                color="#F2F2F2"
                @click="
                  toggleDeleteResourceModal({
                    Delete: true,
                    id: row.item.id,
                  })
                "
                v-if="row.item.id != 145 && row.item.id != 62"
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="22"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <v-card flat class="footer-card">
      <v-card-text>
        <v-row no-gutters>
          <v-col
            class="mb-4"
            align-self="center"
            cols="12"
            xs="12"
            sm="6"
            md="4"
          >
            <div
              class="justify-center justify-md-start d-flex px-5 tableHeader-text"
            >
              <div class="alignSelf-center mr-3">Show</div>
              <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                <v-select
                  class=""
                  :items="itemsPerPage"
                  hide-details
                  v-model="
                    campaignDataTable[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  dense
                ></v-select>
              </div>

              <div class="alignSelf-center">
                {{
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                }}
                of
                {{
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                }}
              </div>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="4" md="4">
            <div>
              <v-pagination
                v-model="
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]
                "
                :length="
                  campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.TOTAL_PAGE]
                "
                :total-visible="5"
                color="#D30024"
                class="pagination"
              >
              </v-pagination>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <delete-resource-confirmation></delete-resource-confirmation>
  </div>
</template>
<script>
import {
  API_RESOURCE_COMMON_GET,
  API_RESOURCE_DOWNLOAD,
  GET_RESOURCES_CATEGORYLIST,
} from "@/constants/APIUrls";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import {
  PAGE_INFO,
  DATA,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
export default {
  name: "CampaignManagement",
  components: {
    DeleteResourceConfirmation: () =>
      import("./DeleteResourceConfirmation/DeleteResourceConfirmation.vue"),
  },
  data() {
    return {
      dataOptions: { sortBy: [], sortDesc: [] },
      campaignDataTable: {
        headers: [
          {
            sortable: false,
            text: "Title",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            text: "Notes",
            align: "center",
            sortable: false,
            class: ["tableHeader-text", "tableHeader-bg"],
          },
        
          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      user_id: localStorage.getItem("user_id"),
      btnLoading: false,
      indexClicked: undefined,
      category2: "wizfit",
      category: 9,
      categoryList: [],
    };
  },
  created() {
    this.$root.$refs.resourceCommon = this;
    this.debounceGetCampaignManagementData = _.debounce(
      this.getCampaignManagementData,
      500
    );
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    selectedCampaignData() {
      return this.selectedCampaign;
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "campaignDataTable.page_info.page_number": function () {
      this.getCampaignManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "campaignDataTable.page_info.detail_count": function () {
      this.getCampaignManagementData();
    },
  },
  methods: {
    ...mapActions({
      // campaign Modal

      toggleDeleteResourceModal: "resourceCommon/toggleDeleteResourceModal",
      showToast: "snackBar/showToast",
    }),
    getCampaignManagementData() {
      const self = this;
      this.campaignDataTable.loading = true;
      const successHandler = (res) => {
        self.campaignDataTable.items = res.data.resource_list;
        self.campaignDataTable[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.campaignDataTable[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.campaignDataTable[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.campaignDataTable.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.campaignDataTable.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[ITEMS_PER_PAGE] =
        this.campaignDataTable[PAGE_INFO][ITEMS_PER_PAGE];
      dataTableParams[PAGE_NUMBER] =
        this.campaignDataTable[PAGE_INFO][PAGE_NUMBER];
      dataTableParams.category_id = this.category;
      Axios.request_GET(
        API_RESOURCE_COMMON_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getReport(id) {
      const self = this;
      self.btnLoading = true;
      this.indexClicked = id;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      formData["id"] = id;
      Axios.request_GET_BLOB(
        API_RESOURCE_DOWNLOAD,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    deleteCampaign(ID) {
      const self = this;
      self.formLoading = true;
      const successHandler = () => {
        self.formLoading = false;

        this.showToast({
          message: "Record Deleted",
          color: "s",
        });
        this.getCampaignManagementData();
      };
      const failureHandler = () => {
        self.formLoading = false;
      };
      let formData = {};
      console.log(formData);
      Axios.request_DELETE(
        "/school/school_resource/" + ID,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    route() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.resourceCommonUpload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
    routeWizfit() {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.resource.name,
      });
    },
    routeGame() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.gameresourceManagement.name,
      });
    },
    getCategoryList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.categoryList = data.category_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson.search = "Wizfit";
      return Axios.request_GET(
        GET_RESOURCES_CATEGORYLIST,

        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    editResource(item) {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.resourceCommonUpload.name,
        query: { id: item.id },
      });
    },
    videoURL(row) {
      window.open(row, "_blank");
    },
  },
  mounted() {
    this.getCategoryList();
    this.getCampaignManagementData();
  },
};
</script>
<style scoped>
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  right: 10px;
  letter-spacing: 0px;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.alignSelf-center {
  align-self: center;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 700;
}
.footer-card.v-card {
  max-height: 70px;
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
  /*min-width: 1500px;*/
}
.table-div {
  overflow-x: scroll;
}

.v-btn {
  font-family: Lato;
  font-weight: 600;
}
.v-input {
  font-family: Lato;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .v-input.search-input {
    display: flex;
    width: 240px;
    margin-left: 10px;
  }
  .v-icon.notranslate.mobile-menu {
    position: absolute;
    top: 25px;
  }
  .table-div {
    overflow: scroll;
  }
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
