var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-7 py-4",staticStyle:{"background":"#f7f7f7","height":"auto"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"10"}},[_c('h4',[_vm._v("Select Category")]),_c('v-radio-group',{staticClass:"radio-group py-0 ma-0 px-1",attrs:{"small":"","row":""},model:{value:(_vm.category2),callback:function ($$v) {_vm.category2=$$v},expression:"category2"}},[_c('v-radio',{attrs:{"disabled":_vm.formLoading,"color":"#2C1963","label":"Wizfit","value":"wizfit"}}),_c('v-radio',{attrs:{"disabled":_vm.formLoading,"color":"#2C1963","label":"Game","value":"game"},on:{"change":function($event){return _vm.routeGame()}}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-btn',{staticClass:"px-11 text-capitalize ml-3",staticStyle:{"border-radius":"10px","margin-top":"35px"},attrs:{"color":"#38227A","dark":"","height":"45px"},on:{"click":function($event){return _vm.route()}}},[_c('span',[_vm._v("Upload")])]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mobile-menu"},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,false,266860307)},[_c('v-list',[_c('v-list-item-group',{attrs:{"active-class":"active-class"}},[_c('v-list-item',{on:{"click":function($event){return _vm.route()}}},[_c('v-list-item-title',[_vm._v("Upload")])],1)],1)],1)],1):_vm._e()],1)],1),_c('v-row',[_c('v-col',{staticClass:"pl-3",attrs:{"cols":"12","md":"3"}},[_c('v-autocomplete',{staticClass:"formFields",attrs:{"label":"Category","outlined":"","dense":"","items":_vm.categoryList,"item-value":"id","item-text":"category_name","color":"#7253CF"},on:{"change":function($event){return _vm.getCampaignManagementData()}},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),_c('div',{staticClass:"table-div",staticStyle:{"margin-top":"25px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"hide-default-footer":"","fixed-header":"","headers":_vm.campaignDataTable.headers,"items":_vm.campaignDataTable.items,"loading":_vm.campaignDataTable.loading,"items-per-page":_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.ITEMS_PER_PAGE],"server-items-length":_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.ITEMS_PER_PAGE],"loading-text":"Loading... Please wait","mobile-breakpoint":""},scopedSlots:_vm._u([{key:"item",fn:function(row){return [_c('tr',[_c('td',{staticClass:"text-center row-item"},[_c('div',[_vm._v(" "+_vm._s(row.item.title)+" ")])]),_c('td',{staticClass:"text-center row-item"},[_vm._v(_vm._s(row.item.notes))]),_c('td',{staticClass:"text-center row-item"},[_c('v-chip',{staticClass:"actionColumn-btn",attrs:{"color":"#F2F2F2"},on:{"click":function($event){return _vm.editResource(row.item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit")])])],1),(row.item.document)?_c('v-chip',{staticClass:"actionColumn-btn",attrs:{"color":"#F2F2F2","loading":_vm.btnLoading && _vm.indexClicked === row.item.id},on:{"click":function($event){return _vm.getReport(row.item.id)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-download ")])]}}],null,true)},[_c('span',[_vm._v("Download ")])])],1):_c('v-chip',{staticClass:"actionColumn-btn",attrs:{"color":"#F2F2F2"}},[_c('a',{attrs:{"href":row.item.video_url,"target":"_blank"}},[_vm._v(" URL")])]),(row.item.id != 145 && row.item.id != 62)?_c('v-chip',{staticClass:"actionColumn-btn",attrs:{"color":"#F2F2F2"},on:{"click":function($event){return _vm.toggleDeleteResourceModal({
                  Delete: true,
                  id: row.item.id,
                })}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"icons",attrs:{"dark":"","color":"#6B6B6B","size":"22"}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1):_vm._e()],1)])]}}])})],1),_c('v-card',{staticClass:"footer-card",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mb-4",attrs:{"align-self":"center","cols":"12","xs":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"justify-center justify-md-start d-flex px-5 tableHeader-text"},[_c('div',{staticClass:"alignSelf-center mr-3"},[_vm._v("Show")]),_c('div',{staticClass:"alignSelf-center mr-5 pb-2",staticStyle:{"width":"60px"}},[_c('v-select',{attrs:{"items":_vm.itemsPerPage,"hide-details":"","dense":""},model:{value:(
                  _vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][
                    _vm.$API_KEYS.ITEMS_PER_PAGE
                  ]
                ),callback:function ($$v) {_vm.$set(_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO], 
                    _vm.$API_KEYS.ITEMS_PER_PAGE
                  , $$v)},expression:"\n                  campaignDataTable[$API_KEYS.PAGE_INFO][\n                    $API_KEYS.ITEMS_PER_PAGE\n                  ]\n                "}})],1),_c('div',{staticClass:"alignSelf-center"},[_vm._v(" "+_vm._s(_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.PAGE_NUMBER])+" of "+_vm._s(_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.TOTAL_PAGE])+" ")])])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"4","md":"4"}},[_c('div',[_c('v-pagination',{staticClass:"pagination",attrs:{"length":_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.TOTAL_PAGE],"total-visible":5,"color":"#D30024"},model:{value:(
                _vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO][_vm.$API_KEYS.PAGE_NUMBER]
              ),callback:function ($$v) {_vm.$set(_vm.campaignDataTable[_vm.$API_KEYS.PAGE_INFO], _vm.$API_KEYS.PAGE_NUMBER, $$v)},expression:"\n                campaignDataTable[$API_KEYS.PAGE_INFO][$API_KEYS.PAGE_NUMBER]\n              "}})],1)])],1)],1)],1),_c('delete-resource-confirmation')],1)}
var staticRenderFns = []

export { render, staticRenderFns }